//
// Cookie Consent
//

import jQuery from "jquery";

function giveCookieConsent(event) {
    createCookieConsentCookie("accept", 90);
    _handleCookieConsentAction(event);
}

function declineCookieConsent(event) {
    createCookieConsentCookie("decline", 1);
    _handleCookieConsentAction(event);
}

function revokeCookieConsent(event) {
    deleteCookieConsentCookie();
    _handleCookieConsentAction(event);
}

function _handleCookieConsentAction(event) {
    event.preventDefault();
    jQuery(event.target).removeAttr("data-dirty");
    jQuery("#cookie-consent-overlay").fadeOut("fast");
    window.location = window.location;
}

function createCookieConsentCookie(action, rememberDays) {
    const expire = new Date();
    expire.setTime(expire.getTime() + rememberDays * 24 * 60 * 60 * 1000);
    let cookie = [
        "cookieConsent=" + action,
        "path=/",
        "expires=" + expire.toUTCString(),
        "Max-Age=" + rememberDays * 24 * 60 * 60,
        "SameSite=Strict",
    ];
    if (window.location.protocol === "https:") {
        cookie.push("secure=secure");
    }
    document.cookie = cookie.join(";");
}

function deleteCookieConsentCookie() {
    document.cookie =
        "cookieConsent=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;Max-Age=0;";
}

function hasCookieConsentCookie(action) {
    return !!document.cookie.split(";").filter(function (item) {
        return item.includes("cookieConsent=" + action);
    }).length;
}

jQuery(function () {
    if (hasCookieConsentCookie("accept")) {
        window.hasCookieConsent = true;
    } else {
        window.hasCookieConsent = false;
    }

    const overlay = jQuery("#cookie-consent-overlay");
    if (overlay === undefined) {
        return;
    }

    if (hasCookieConsentCookie("accept")) {
        overlay.hide();
        jQuery('*[data-tracking-status="off"]').hide();
    } else if (hasCookieConsentCookie("decline")) {
        overlay.hide();
        jQuery('*[data-tracking-status="on"]').hide();
    } else {
        jQuery("footer").css("margin-bottom", overlay.outerHeight() + 40);
        overlay.show();
        jQuery('*[data-tracking-status="on"]').hide();
    }

    jQuery('*[data-tracking-action="consent"]')
        .off()
        .on("click", giveCookieConsent);

    jQuery('*[data-tracking-action="decline"]')
        .off()
        .on("click", declineCookieConsent);

    jQuery('*[data-tracking-action="disable"]')
        .off()
        .on("click", revokeCookieConsent);
});

import * as bootstrap from "bootstrap";
import jQuery from "jquery";

document.addEventListener("DOMContentLoaded", function (event) {
    let collapseElementList = [].slice.call(
        document.querySelectorAll(".ce_accordion")
    );
    let collapseList = collapseElementList.map(function (collapseEl) {
        let toggler = collapseEl.querySelector(".toggler");
        let iconEl = document.createElement("i");
        iconEl.classList.add("fas");
        iconEl.classList.add("fa-fw");
        iconEl.classList.add("fa-plus");
        toggler.prepend(iconEl);

        let content = collapseEl.querySelector(".accordion");
        content.classList.add("collapse");

        let collapseObj = new bootstrap.Collapse(content, { toggle: false });

        toggler.addEventListener("click", function () {
            collapseObj.toggle();
        });

        // Bootstrap v4 uses jQuery Events which can not be handled by native JavaScript EventListeners
        jQuery(content).on("hide.bs.collapse", function () {
            console.debug("jQuery hide.bs.collapse");
            iconEl.classList.remove("fa-minus");
            iconEl.classList.add("fa-plus");
        });
        jQuery(content).on("show.bs.collapse", function () {
            console.debug("jQuery show.bs.collapse");
            iconEl.classList.remove("fa-plus");
            iconEl.classList.add("fa-minus");
        });

        return collapseObj;
    });
});

import "bootstrap";

import jQuery from "jquery";
import { tns } from "tiny-slider/src/tiny-slider.module";

jQuery(document).ready(function () {
    jQuery(".logo-slider").each(function () {
        tns({
            container: jQuery(this).find(".slider-container").first()[0],
            items: 5,
            gutter: 15,
            speed: 500,
            nav: false,
            autoplay: true,
            autoplayButtonOutput: false,
            prevButton: jQuery(this).find("[data-slide='prev']").first()[0],
            nextButton: jQuery(this).find("[data-slide='next']").first()[0],
            responsive: {
                0: {
                    items: 1,
                },
                780: {
                    items: 2,
                },
                960: {
                    items: 3,
                },
                1100: {
                    items: 4,
                },
            },
        });
    });
});

import "./cookie-consent";
import "./_accordion";

function fixBrandFilterStickyOffset() {
    let headerHeight = document.getElementById("header").clientHeight;
    let elements = document.getElementsByClassName("exhibitor-actions");
    let index = 0,
        length = elements.length;
    for (; index < length; index++) {
        elements[index].style.top = headerHeight + "px";
    }
}
window.addEventListener("resize", fixBrandFilterStickyOffset);
window.addEventListener("load", fixBrandFilterStickyOffset);

function fixEventFilterStickyOffset() {
    let headerHeight = document.getElementById("header").clientHeight;
    let actionsHeight = 0;
    let eventListElements = document.getElementsByClassName("mod_event_list");
    let index = 0,
        length = eventListElements.length;
    for (; index < length; index++) {
        let index = 0,
            length = 0;
        let eventList = eventListElements[index];

        let elements = eventList.getElementsByClassName("event-actions");
        index = 0;
        length = elements.length;
        for (; index < length; index++) {
            elements[index].style.top = headerHeight + "px";
            actionsHeight = elements[index].clientHeight;
        }

        let headlineElements = eventList.getElementsByTagName("h3");
        index = 0;
        length = headlineElements.length;
        for (; index < length; index++) {
            headlineElements[index].style.top =
                headerHeight + actionsHeight + "px";
        }
    }
}
window.addEventListener("resize", fixEventFilterStickyOffset);
window.addEventListener("load", fixEventFilterStickyOffset);
